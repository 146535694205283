<template>
  <div class="appointmentList-view">
    <div class="flex-a-c mb40 mt70">
      <div class="fs7 fwbold cA1 w300 ">1、考核规则名称:</div>
      <el-input
          type="text"
          placeholder="请输入内容"
          v-model="rulesTitle"
          maxlength="10"
          style="width: 300px"
          show-word-limit
        >
        </el-input>
    </div>
    <div class="flex-a-c mb40">
      <div class="fs7 fwbold cA1 w300 ">2、考核时间周期:</div>
      <el-date-picker
        v-if="!kpiId"
        class="w300"
        
        v-model="createTime"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-date-picker
        v-else
        class="w340"
        v-model="createTime"
        :disabled="true"
        type="daterange"
        :start-placeholder="start"
        :end-placeholder="end">
      </el-date-picker>
    </div>
    <div class="flex-a-c mb40">
      <div class="fs7 fwbold cA1 w300 ">3、考核对象:</div>
      <div class="d-flex" v-if="nameList.length !== 0">
        <div class="d-flex mr30" 
             style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:220px;">
          
          {{ nameString }}
        </div>
      </div>
      
      <el-button
          class="v-primary"
          type="primary"
          @click="addRulesMember"
          >选择</el-button
        >
    </div>
    <div class="flex-a-c mb40">
      <div class="fs7 fwbold cA1 w300 ">4、考核内容(可多种并存):</div>
      <el-button
          class="v-primary"
          type="primary"
          @click="addRules"
          >{{ruleContent.length == 0 ? '添加' : '编辑'}}</el-button
        >
    </div>
    
    <div class="d-flex flex-column mb40">
      <div style="margin-left:300px" 
           v-for="(item,index) in ruleContent" 
           :key="index">
        <div class="flex-a-c mb20" v-if="item.type == 1">
          <div class="w200">添加客户数</div>
          <div class="flex-a-c">
            <el-input
              type="number"
              placeholder="1~9999999"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="item.value"
              max="9999999"
              min="1"
              style="width: 120px"
              @change="() => {
                item.value =  (item.value > 9999999 || item.value == 0 ) ? '' : item.value
              }"
            >
            </el-input>个
            <i class="el-icon-delete ml20" @click="deleteBtn(item,index)"></i>
          </div>
        </div>
        <div class="flex-a-c mb20" v-if="item.type == 2">
          <div class="w200">新用户访问数</div>
          <div class="flex-a-c">
            <el-input
              type="number"
              placeholder="1~9999999"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="item.value"
              max="9999999"
              min="1"
              style="width: 120px"
              @change="() => {
                item.value =  (item.value > 9999999 || item.value == 0 ) ? '' : item.value
              }"
            >
            </el-input>个
            <i class="el-icon-delete ml20" @click="deleteBtn(item,index)"></i>
          </div>
        </div>
        <div class="flex-a-c mb20" v-if="item.type == 3">
          <div class="w200">客户跟进率</div>
          <div class="flex-a-c">
            <el-input
              type="number"
              placeholder="1~100"
              v-model="item.value"
              max="100"
              min="1"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 120px"
              @change="() => {
                item.value =  (item.value > 100 || item.value == 0 ) ? '' : item.value
              }"
            >
            </el-input>%
            <i class="el-icon-delete ml20" @click="deleteBtn(item,index)"></i>
          </div>
        </div>
        <div class="flex-a-c mb20" v-if="item.type == 4">
          <div class="w200">商城销售金额</div>
          <div class="flex-a-c">
            <el-input
              type="number"
              placeholder="1~9999999"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="item.value"
              max="9999999"
              min="1"
              style="width: 120px"
              @change="() => {
                item.value =  (item.value > 9999999 || item.value == 0 ) ? '' : item.value
              }"
            >
            </el-input>元
            <i class="el-icon-delete ml20" @click="deleteBtn(item,index)"></i>
          </div>
        </div>
        
      </div>
      
      <el-button
          class="v-primary w100"
          type="primary"
          width=""
          @click="publish"
          >发布</el-button>
      
    </div>

    <!-- 部门成员列表 -->
    <el-dialog
          class="label_dialog"
          title="选择考核对象"
          width="50%"
          center
          :visible.sync="editVisible"
          :modal-append-to-body="false"
          @closed="editVisible = false"
        >
        <div class="w100p flex-a-b-c mb50">
          <el-checkbox class="" v-if="memberList.length !== 0 " v-model="checkAllMember" @change="checkAllMemberBox_tap">全选</el-checkbox>
          <span v-else></span>
          <div class="flex-a-c">
            <el-select
              v-model="currentDepartment"
              style="width: 150px;margin-right:20px"
              placeholder="请选择部门"
            >
              <el-option
                v-for="(item) in departmentList"
                :key="item.departmentId"
                :label="item.name"
                :value="item.departmentId"
              />
            </el-select>
            <el-input
              type="text"
              placeholder="请输入员工姓名"
              v-model="departmentKey"
              style="width: 150px; margin-right:30px">
            </el-input>
            <el-button
                class="v-primary"
                style=""
                type="primary"
                @click="queryPageMember"
                >搜索</el-button>
          </div>
          
        </div>
        
        <div class="flex-a-c flex-wrap-y mb30" v-if="memberList.length !== 0">
          <el-checkbox-group  v-model="checkedMemberArr">
          <el-checkbox  class="mr30 mb30"
                        style="margin-left: 0"
                        v-for="(item, index) in memberList"
                        :key="index"
                        @change="checkbox_tap"
                        :label="item.empId"
                        border
                        >{{ item.employeeName }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex-c-c mb30" v-else-if="!currentDepartment">
          <p>请先选择部门</p>
        </div>
        <div class="flex-c-c mb30" v-else>
          <p>{{hintText}}</p>
        </div>
        
        <div class="w100p mt50 d-flex justify-content-center">
          <el-pagination
              background
              @current-change="currentChange"
              :current-page="currentPage"
              :page-size="25"
              layout="total, prev, pager, next"
              :total="productList.length"
            />
        </div>
        <div class="d-flex justify-content-center">
          <div class="w100 mt50 d-flex justify-content-center">
            <el-button
              class="v-primary"
              style="margin-right: 20px"
              type="primary"
              @click="editVisible = false"
              >取消</el-button
            >
            <el-button
              class="v-primary"
              type="primary"
              @click="saveMemberContent"
              >确定</el-button
            >
          </div>
        </div>
        
        
    </el-dialog>
    <!-- 规则列表 -->
    <el-dialog
          class="label_dialog "
          title="选择规则"
          width="400px"
          center
          :visible.sync="rulesVisible"
          :modal-append-to-body="false"
          @closed="rulesVisible = false"
        >
        <div class="d-flex flex-column flex-wrap-y mb30 pl50">
          <el-checkbox class="mb30" v-model="checkAll" @change="checkAllBox_tap">全选</el-checkbox>
          <el-checkbox-group  class="d-flex flex-column" 
                              v-model="checkedArr">
            <el-checkbox  class="mr30 mb30"
                          style="margin-left: 0"
                          v-for="(item, index) in checkedRules"
                          :key="index"
                          @change="checkbox_tap(index)"
                          :label="item.empId"
                          >{{ item.title }}</el-checkbox>
          </el-checkbox-group>
        </div>

        <el-button
          class="v-primary ml10"
          style="margin-left:150px !important"
          type="primary"
          @click="saveRuleContent"
          >保存</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryPageEmployee,
  queryPageDepartment
} from "@/api/companyManage.js";
import {
  addEmployeeKpi,
  getEmployeeKpiDetails
} from "@/api/employee.js";
import { getDataTimeSec } from "@/utils";


export default {
  name: "appointmentList",
  data() {
    return {
      rulesTitle:'',
      createTime:'',
      start:'',
      end:'',
      editVisible:false,
      rulesVisible:false,
      checkedArr:[], //当前页显示内容
      checkedMemberArr:[], //选中的成员
      memberList:[], // 成员列表
      checkAll:false, //考核规则全选
      checkAllMember:false,// 全选成员
      checkedRules:[
        {
          title:"添加客户数",
          type:1,
          empId:1
        },
        {
          title:"新用户访问数",
          type:2, 
          empId:2
        },
        {
          title:"客户跟进率",
          type:3,
          empId:3
        },
        {
          title:"商城销售金额",
          type:4,
          empId:4
        }
      ],
      ruleContent:[], //选好的考核规则
      memberContent:[], //选好的考核人员
      productList:[], //所有数据
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, //当前页数 ，默认为1
      pageSize: 25, // 每页显示数量
      currentPageData: [], //当前页显示内容
      departmentList:"",//部门数据
      currentDepartment:"",//选择的当前部门
      departmentKey:'',// 部门搜索关键字
      hintText:'', //提示文字
      nameList:[],
      isLoading:false,
      kpiId:''
    };
  },
  watch:{
    createTime(val,old){
      this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59" 
      
      console.log(this.end)
    },
    checkedArr(val,old){
      if(val.length == 4){
        this.checkAll = true
      }else{
        this.checkAll = false
      }
    },
    checkedMemberArr(val,old){
      console.log(val)
      if(val.length == this.productList.length){
        this.checkAllMember = true
      }else{
        this.checkAllMember = false
      }
    },
    currentDepartment(val,old){
      console.log(val)
    }
  },
  created() {
    console.log()
    if(this.$route.query.id){
      this.kpiId = this.$route.query.id
      this.queryEmployeeKpiDetails()
    }
  },
  
  computed:{
     nameString () {
       let names =  '' 
       this.nameList.forEach((item,index)=>{
         names +=  item.name+'、'
       })
       if(names.length > 15){
         names = names.slice(0,10) + '...(' +  (this.nameList.length + '人)')
       }
       return  names
     }
  },
  mounted(){
    this.queryPageMember()
  },
  methods: {
    // 设置当前页面数据，对数组操作的截取规则为[0~10],[10~20]...,
    setCurrentPageData() {
        let begin = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        this.memberList = this.productList.slice(
            begin,
            end
        );
    },
    addRulesMember(item){
      this.editVisible = true
      let data = {
        pageSize:9999,
      }
      queryPageDepartment(data)
      .then(res=>{
        this.departmentList = res.data?.pageInfo?.list
        console.log(this.departmentList)
        this.departmentList.unshift({
          departmentId:'',
          name:'全部'
        })
      })
    },
    queryPageMember(){
      let data = {
        pageSize:9999,
        departmentId:this.currentDepartment,
        name:this.departmentKey,
      }
      queryPageEmployee(data)
      .then(res=>{
        console.log(res)
        if(res.data && res.data.pageInfo && res.data.pageInfo.list){
          
          this.productList = res.data.pageInfo.list
          this.setCurrentPageData()
        }else{
          this.productList = []
          this.memberList = []
          this.hintText = '暂无数据'
        }
        
      })
      .catch(res=>{
        this.hintText = '暂无数据'
      })
    },
    addRules(){
      this.rulesVisible = true
    },
    //设置checkbox选择状态
    checkbox_tap(val){
      console.log('val',val)
      
    },
    checkAllBox_tap(val){
      let than = this
      if(val){
        than.checkedArr = []
        than.checkedRules.forEach((item,index)=>{
          than.checkedArr.push(item.empId)
        })
      }else{
        than.checkAll = true
          than.checkedArr = []
      }
    },
    saveRuleContent(){
      this.rulesVisible = false
      this.ruleContent = JSON.parse(JSON.stringify(this.checkedArr)) 
      this.ruleContent = this.ruleContent.map((item,index)=>{
        return {
          type:item,
          value:''
        }
      })
    },
    saveMemberContent(){
      let than = this
      than.editVisible = false
      
      than.memberContent = JSON.parse(JSON.stringify(than.checkedMemberArr))
      than.nameList = []
      for(var i in this.memberContent){
        than.productList.forEach((item,index)=>{
          if(this.memberContent[i] == item.empId){
            than.nameList.push({
              name:item.employeeName
            })
          } 
          
        })
      }
    },

    deleteBtn(item,index){
      for(var i in this.checkedArr){
        if(this.checkedArr[i] == item.type){
          this.checkedArr.splice(i,1)
        }
      }
      this.ruleContent.splice(index,1)
    },
    currentChange(val){
      this.currentPage = val
      this.setCurrentPageData()
    },
    checkAllMemberBox_tap(val){
      let than = this
      if(val){
        than.checkedMemberArr = []
        than.productList.forEach((item,index)=>{
          than.checkedMemberArr.push(item.empId)
        })
      }else{
        than.checkAllMember = false
        than.checkedMemberArr = []
      }
    },
    publish(){
      if( !this.rulesTitle ||  !this.start  || !this.end || this.ruleContent.length == 0 || this.memberContent.length == 0){
        
        return this.$message.error('请完善规则内容')
      }
      for(var i in this.ruleContent){
        if(!this.ruleContent[i].value){
          return this.$message.error('请完善考核内容')
        }
      }
      
      let data = {
        name:this.rulesTitle,
        startTime:this.start,
        endTime:this.end,
        rules:JSON.stringify(this.ruleContent),
        employees:'',
        kpiId:this.kpiId
      }
      
      this.memberContent.forEach((item,index)=>{
        data.employees += item + ','
      })
      console.log(data.employees)
      addEmployeeKpi(data)
      .then(res=>{
        console.log(res)
        if(res.code == 200){
          this.$message.success('添加成功')
          this.$router.go(-1)
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //获取详情
    queryEmployeeKpiDetails(){
      let data ={
        kpiId:this.kpiId
      }
      getEmployeeKpiDetails(data)
      .then((res)=>{
        let kpidata = res.data.employee_kpi
        this.rulesTitle = kpidata.name
        this.start = kpidata.startTime
        this.end = kpidata.endTime
        this.ruleContent = kpidata.rules
        this.checkedArr = this.ruleContent.map((item,index)=>{
          return item.type
        })

        this.checkedMemberArr = kpidata.employees.map((item,index)=>{
          return item.empId
        })
        this.nameList = kpidata.employees.map((item,index)=>{
          return {
            name:item.nickeName
          }
        })
        this.memberContent = kpidata.employees.map((item,index)=>{
          return item.empId
        })

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view{
  background: #ffffff;
  padding: 24px;
}
/deep/ .el-dialog__body{
  padding: 50px 50px 60px;
}
</style>